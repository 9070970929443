import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { useAuthProvider } from "./Authentication/hooks/auth-provider";

// eslint-disable-next-line react-hooks/rules-of-hooks
const { AuthProvider } = useAuthProvider();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);
