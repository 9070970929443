import React from "react";
import { Box } from "@mui/material";

const Logo = () => {
  return (
    <Box height="3vh" width="9vw">
      <img
        src={process.env.REACT_APP_LOGO_PATH}
        alt="Company Logo"
        style={{ height: "100%" }}
      />{" "}
    </Box>
  );
};

export default Logo;
