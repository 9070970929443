import React from "react";
import { Box } from "@mui/material";

const UserMessage = ({ message }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#F4F4F4",
        padding: {
          xs: "10px 12px",
          sm: "12px 14px",
          md: "15px 15px",
        },
        borderRadius: "20px",
        maxWidth: {
          xs: "80%",
          sm: "70%",
          md: "60%",
        },
        wordBreak: "break-word",
        overflowWrap: "break-word",
        flexShrink: 1,
        alignSelf: "flex-end",
        marginBottom: "10px",
        marginRight: {
          xs: "5px",
          sm: "8px",
          md: "10px",
        },
        fontSize: {
          xs: "14px",
          sm: "15px",
          md: "16px",
        },
        fontFamily:
          "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
        whiteSpace: "pre-wrap",
      }}
    >
      {message}
    </Box>
  );
};

export default UserMessage;
