import React, { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const AssistantMessage = ({ message }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    const textToCopy = new DOMParser().parseFromString(message, "text/html")
      .body.innerText;
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      sx={{
        width: "100%",
        marginBottom: "10px",
      }}
    >
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        sx={{
          width: "100%",
        }}
      >
        <Box
          margin={1}
          sx={{
            borderRadius: "50%",
            border: "1px solid #343D3F",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: { xs: "40px" },
            height: { xs: "40px" },
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
          }}
        >
          <SmartToyOutlinedIcon sx={{ padding: "15px" }} />
        </Box>

        <Box
          sx={{
            fontSize: {
              xs: "14px",
              sm: "15px",
              md: "16px",
            },
            fontFamily:
              "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
            maxWidth: "100%",
            wordBreak: "break-word",
            lineHeight: "1.5",
            "& h1": {
              fontSize: {
                xs: "18px",
                md: "20px",
              },
              marginBottom: "8px",
            },
            "& h2": {
              fontSize: {
                xs: "16px",
                md: "18px",
              },
              marginBottom: "6px",
            },
            "& p": {
              marginBottom: "10px",
            },
            "& ul": {
              paddingLeft: "20px",
              marginBottom: "10px",
            },
            "& li": {
              marginBottom: "4px",
            },
          }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{
          width: "100%",
          marginTop: "8px",
        }}
      >
        <Tooltip title={isCopied ? "Copied!" : "Copy"}>
          <IconButton onClick={handleCopy} size="small">
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default AssistantMessage;
