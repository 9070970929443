import React from "react";
import { CircularProgress, Box } from "@mui/material";

const LoadingComponent = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
  >
    <CircularProgress color="secondary" />
  </Box>
);

export default LoadingComponent;
