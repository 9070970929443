import { Box } from "@mui/material";
import React from "react";
import Header from "./Header";
import ChatContainer from "./ChatContainer";

const ChatUI = ({ token }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="98vh"
    >
      <Box width="100%" sx={{ flex: "0 0 auto" }}>
        <Header />
      </Box>
      <Box
        width="100%"
        sx={{
          flex: "1 1 auto",
          overflowY: "auto",
        }}
      >
        <ChatContainer token={token} />
      </Box>
    </Box>
  );
};

export default ChatUI;
