import { useState, useEffect, useReducer } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { validateToken } from "../../api-service";
import { loginRequest } from "../authConfig";

const initialState = {
  loading: true,
  authorized: false,
  error: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_AUTHORIZED":
      return { ...state, authorized: action.payload };
    case "SET_ERROR":
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

function useAuth() {
  const { instance, accounts, inProgress } = useMsal();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [token, setToken] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const acquireAndValidateToken = async () => {
      try {
        const activeAccount = instance.getActiveAccount();

        if (!activeAccount) {
          throw new Error("No active account! Please authenticate.");
        }

        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: activeAccount,
        });

        if (!isMounted) return;

        const acquiredToken = response.accessToken;
        setToken(acquiredToken);

        const isValid = await validateToken(acquiredToken);

        if (isMounted) {
          if (isValid) {
            dispatch({ type: "SET_LOADING", payload: false });
            dispatch({ type: "SET_AUTHORIZED", payload: true });
            dispatch({ type: "SET_ERROR", payload: null });
          } else {
            dispatch({ type: "SET_LOADING", payload: false });
            dispatch({ type: "SET_AUTHORIZED", payload: false });
            dispatch({
              type: "SET_ERROR",
              payload:
                "Unauthorized access. Please click on the LOG OUT button to log out from the current account, then try logging in with a different account.",
            });
          }
        }
      } catch (error) {
        if (!isMounted) return;
        console.error("Token acquisition error:", error);
        dispatch({ type: "SET_LOADING", payload: false });
        dispatch({ type: "SET_AUTHORIZED", payload: false });
        dispatch({
          type: "SET_ERROR",
          payload: error.message || "Token acquisition failed.",
        });
      }
    };

    if (inProgress === InteractionStatus.None) {
      if (instance.getActiveAccount()) {
        acquireAndValidateToken();
      } else {
        instance.loginRedirect(loginRequest).catch((error) => {
          console.error("Login failed:", error);
        });
      }
    }

    return () => {
      isMounted = false;
    };
  }, [instance, inProgress]);

  const handleLogout = () => {
    const account = instance.getActiveAccount();

    instance
      .logoutRedirect({
        account: account,
        onRedirectNavigate: () => {
          instance.setActiveAccount(null);
          return true;
        },
      })
      .catch((error) => {
        console.error("Logout failed:", error);
      });
  };

  const account = accounts && accounts[0];

  return { status: state, token, handleLogout, account };
}

export default useAuth;
