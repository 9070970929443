import React from "react";
import ChatUI from "./Components/ChatUI";
import useAuth from "./Authentication/hooks/useAuth";
import UnauthorizedComponent from "./Components/UnauthorizedComponent";
import LoadingComponent from "./Components/LoadingComponent";
import AuthContext from "./Authentication/AuthContext";

const App = () => {
  const { status, handleLogout, account, token } = useAuth();

  let userName = "User";
  if (account) {
    userName = account.name || account.username || "User";
  }

  if (status.loading) {
    return <LoadingComponent />;
  }

  if (status.error) {
    return (
      <UnauthorizedComponent error={status.error} onLogout={handleLogout} />
    );
  }

  if (status.authorized) {
    return (
      <AuthContext.Provider value={{ userName, handleLogout }}>
        <ChatUI token={token} />
      </AuthContext.Provider>
    );
  }

  return null;
};

export default App;
