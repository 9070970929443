const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const sendMessage = async (message, threadId, signal, token) => {
  try {
    const payload = {
      message,
      threadId: threadId || null,
    };

    const response = await fetch(`${API_BASE_URL}/getAnswer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
      signal,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    if (error.name === "AbortError") {
      console.warn("Request aborted:", error);
    } else {
      console.error("Error sending message:", error);
    }
    throw error;
  }
};

export const validateToken = async (token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/authCheck`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Token validation failed. Status: ${response.status}`);
    }

    return true;
  } catch (error) {
    console.error("Error validating token:", error);
    return false;
  }
};
