import React, { useState, useRef } from "react";
import Chat from "./Chat";
import Footer from "./Footer";
import { Box, Alert } from "@mui/material";
import BouncingDotsLoader from "./BouncingDotsLoader";
import { sendMessage } from "../api-service";

const ChatContainer = ({ token }) => {
  const [messages, setMessages] = useState([]);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [error, setError] = useState(null);
  const abortControllerRef = useRef(null);

  const handleSendMessage = async (newMessage) => {
    if (newMessage.trim() !== "") {
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: "user", content: newMessage },
      ]);

      setIsBotTyping(true);
      setError(null);

      const controller = new AbortController();
      abortControllerRef.current = controller;

      try {
        const botResponse = await sendMessage(
          newMessage,
          threadId,
          controller.signal,
          token
        );

        if (controller.signal.aborted) {
          return;
        }

        if (botResponse.IsSuccess && botResponse.Data) {
          const data = botResponse.Data;

          if (data.ThreadId) {
            setThreadId(data.ThreadId);
          }

          const contentItem =
            data.Content && data.Content.length > 0 ? data.Content[0] : null;
          if (contentItem && contentItem.text && contentItem.text.value) {
            setMessages((prevMessages) => [
              ...prevMessages,
              { type: "bot", content: contentItem.text.value },
            ]);
          } else {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                type: "bot",
                content: "<p>Unable to retrieve response content.</p>",
              },
            ]);
          }
        } else {
          setError("Something went wrong. Please try again later.");
        }
      } catch (error) {
        console.error("Error sending message:", error);
        if (!controller.signal.aborted) {
          setError("Something went wrong. Please try again later.");
        }
      } finally {
        if (!controller.signal.aborted) {
          setIsBotTyping(false);
        }
      }
    }
  };

  const handleCancelMessage = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    setIsBotTyping(false);
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "bot", content: "<p>The request was cancelled...</p>" },
    ]);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="90vh"
    >
      <Box
        width="100%"
        sx={{
          flex: "1 1 auto",
          overflowY: "auto",
        }}
      >
        <Chat messages={messages} />
        {isBotTyping && <BouncingDotsLoader />}
      </Box>
      {error && (
        <Box width="40%" marginY={2}>
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Box>
      )}
      <Box
        sx={{
          flex: "0 0 auto",
          width: "40%",
          "@media (max-width:700px)": {
            width: "95%",
          },
        }}
      >
        <Footer
          onSendMessage={handleSendMessage}
          onCancelMessage={handleCancelMessage}
          isBotTyping={isBotTyping}
        />
      </Box>
    </Box>
  );
};

export default ChatContainer;
