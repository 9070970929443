import React, { useState } from "react";
import { Box, IconButton, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import StopIcon from "@mui/icons-material/Stop";

const Footer = ({ onSendMessage, onCancelMessage, isBotTyping }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSend = () => {
    if (inputValue.trim() !== "") {
      onSendMessage(inputValue);
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  return (
    <Box
      margin={1}
      display="flex"
      alignItems="center"
      padding="8px 20px"
      sx={{
        backgroundColor: "#F5F5F5",
        borderRadius: "25px",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
      }}
    >
      <TextField
        size="small"
        fullWidth
        placeholder="Please enter your message"
        variant="standard"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        multiline
        maxRows={10}
        slotProps={{
          input: {
            disableUnderline: true,
            sx: {
              backgroundColor: "transparent",
              border: "none",
              padding: 0,
            },
          },
        }}
        sx={{
          flexGrow: 1,
        }}
      />
      <IconButton
        onClick={isBotTyping ? onCancelMessage : handleSend}
        sx={{
          marginLeft: 1,
        }}
      >
        {isBotTyping ? (
          <StopIcon sx={{ color: "black" }} />
        ) : (
          <SendIcon
            sx={{
              color: inputValue.trim() === "" ? "gray" : "black",
            }}
          />
        )}
      </IconButton>
    </Box>
  );
};

export default Footer;
