import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import Divider from "@mui/material/Divider";
import Logo from "./Logo";
import AuthContext from "../Authentication/AuthContext";

function Header() {
  const { handleLogout, userName } = React.useContext(AuthContext);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box
      margin={0.5}
      paddingX={2}
      bgcolor="gray"
      display="flex"
      alignItems="center"
      height="7vh"
      sx={{
        borderRadius: "25px",
        background: process.env.REACT_APP_GRADIENT_COLORS,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Box marginRight="auto">
        <Logo />
      </Box>
      <Box
        sx={{
          width: "4vh",
          height: "4vh",
          border: "3px solid #EDF3F4",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Tooltip title="User settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar sx={{ bgcolor: "transparent", fontSize: "1rem" }}>
              {userName ? userName.charAt(0) : "U"}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        sx={{ mt: "45px", zIndex: "20000" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem sx={{ pointerEvents: "none", userSelect: "none" }}>
          {userName}
        </MenuItem>
        <Divider component="li" />
        <MenuItem
          onClick={() => {
            handleCloseUserMenu();
            handleLogout();
          }}
        >
          <LogoutIcon fontSize="small" />
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default Header;
