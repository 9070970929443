import React from "react";
import Box from "@mui/material/Box";
import { keyframes } from "@emotion/react";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";

const bounceAnimation = keyframes`
  to {
    opacity: 0.1;
    transform: translateY(-8px);
  }
`;

const dotStyle = {
  width: {
    xs: "8px",
    sm: "9px",
    md: "10px",
  },
  height: {
    xs: "8px",
    sm: "9px",
    md: "10px",
  },
  margin: "3px 6px",
  borderRadius: "50%",
  backgroundColor: "#182123",
  opacity: 1,
  animation: `${bounceAnimation} 0.6s infinite alternate`,
};

const BouncingDotsLoader = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        marginBottom: "10px",
        marginX: "auto",
        width: "60%",
        "@media (max-width:700px)": {
          width: "90%",
        },
      }}
    >
      <Box
        margin={1}
        sx={{
          borderRadius: "50%",
          border: "1px solid #343D3F",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: { xs: "40px" },
          height: { xs: "40px" },
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <SmartToyOutlinedIcon sx={{ padding: "15px" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F4F4F4",
          borderRadius: "20px",
          padding: "10px",
          maxWidth: "fit-content",
        }}
      >
        <Box sx={dotStyle} />
        <Box sx={{ ...dotStyle, animationDelay: "0.2s" }} />
        <Box sx={{ ...dotStyle, animationDelay: "0.4s" }} />
      </Box>
    </Box>
  );
};

export default BouncingDotsLoader;
