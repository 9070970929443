import { Box } from "@mui/material";
import React from "react";
import UserMessage from "./UserMessage";
import AssistantMessage from "./AssistantMessage";

const Chat = ({ messages }) => {
  return (
    <Box
      padding="16px"
      sx={{
        marginX: "auto",
        width: "60%",
        "@media (max-width:700px)": {
          width: "90%",
        },
        display: "flex",
        flexDirection: "column",
      }}
    >
      {messages.map((msg, index) =>
        msg.type === "user" ? (
          <UserMessage key={index} message={msg.content} />
        ) : (
          <AssistantMessage key={index} message={msg.content} />
        )
      )}
    </Box>
  );
};

export default Chat;
